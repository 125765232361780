import { buildApiMutationHook, buildApiQueryNoParamsHook } from '../../utils/api'
import { providerAxios } from '../../utils/base.api'
import { INVALIDATE_PUSH_TOKEN, NOTIFICATIONS_LIST, PUSH_TOKEN } from '../endpoints'
import {
  NotificationsListResponse,
  PushTokenParams,
  PushTokenResponse,
  ReadNotificationResponse,
} from './Notification.dto'

export const usePushTokenMutation = buildApiMutationHook<PushTokenParams, PushTokenResponse>(
  (params) => providerAxios.post(PUSH_TOKEN, params),
)

export const useNotificationsList = buildApiQueryNoParamsHook<NotificationsListResponse>(
  'notifications-list',
  () => providerAxios.get(NOTIFICATIONS_LIST),
)

export const useReadNotificationMutation = buildApiMutationHook<
  { notificationId: number },
  ReadNotificationResponse
>((params) => providerAxios.post(`${NOTIFICATIONS_LIST}/${params.notificationId}/seen/`))

export const useInvalidatePushTokenMutation = buildApiMutationHook<{ deviceId: string }>((params) =>
  providerAxios.post(`${INVALIDATE_PUSH_TOKEN}/${params.deviceId}/invalidate/`),
)
